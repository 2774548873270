#loader-pulse {
  width: 100%;
  height: 100vh;
  min-height: 65vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > .l-pulse {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  & > .l-pulse div {
    position: absolute;
    border: 6px solid #4e4e4e;
    opacity: 1;
    border-radius: 50%;
    animation: l-pulse 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  & > .l-pulse div:nth-child(2) {
    animation-delay: -0.5s;
  }
}

@keyframes l-pulse {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
