@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

html,
body {
  margin: 0;
}

html {
  width: 100%;
  font-size: 16px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Sen';

  &::before {
    @extend *;
  }

  &::after {
    @extend *;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Sen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: #eeeeee;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(21, 21, 21, 0.3);
    border-radius: 1000px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Sen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#origin {
  width: 100%;
  height: 100%;
  max-width: 836px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #dde5e9;

  .card {
    border-radius: 10px;
    box-shadow: #cad3e1 0px 1px 4px 0px;
    background-color: white;
    padding: 1rem;
  }
}
